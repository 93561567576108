import { Component, Input, OnInit } from '@angular/core';
import { Card } from '../models/card';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'mean-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  @Input() card: Card;

  editCost = false;
  editHealth = false;
  editRange = false;
  editMovement = false;
  editAbility = false;
  editName = false;
  editDuration = false;
  editPriority = false;
  editType = false;

  constructor(private backendService: BackendService) { }

  ngOnInit(): void {

  }

  save(component) {
    var newVal = {};
    newVal[component] = this.card[component];

    this.backendService.updateCard({ id: this.card.id }, newVal)
      .subscribe(resp => {
        this.card = resp;
        this.toggle(component);
      });
  }

  toggle(component) {
    switch (component.toLowerCase()) {
      case "name":
        this.editName = !this.editName;
        break;
      case "cost":
        this.editCost = !this.editCost;
        break;
      case "health":
        this.editHealth = !this.editHealth;
        break;
      case "range":
        this.editRange = !this.editRange;
        break;
      case "movement":
        this.editMovement = !this.editMovement;
        break;
      case "ability":
        this.editAbility = !this.editAbility;
        break;
      case "duration":
        this.editDuration = !this.editDuration;
        break;
      case "priority":
        this.editPriority = !this.editPriority;
        break;
      case "type":
        this.editType = !this.editType;
        break;
    }
  }

}
