<div class='row home-container'>
    <div class='row home-welcome'>
        <h4 class='welcome-header text-center'>{{welcome.header}}</h4>
        <div class='welcome-message text-center'>{{welcome.message}}</div>
    </div>
    <div class='row'>
        <div class="col-lg-4 col-md-12 mb-4 mb-lg-0" *ngFor="let card of cards">
            <mean-card [card]="card"></mean-card>
        </div>
    </div>
</div>