import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CardListComponent } from './cardList/cardList.component';
import { GameComponent } from './game/game.component';
import { HomeComponent } from './home/home.component';


const routes: Routes = [
  { path: 'cards', component: CardListComponent },
  { path: 'game', component: GameComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
