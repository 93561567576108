import { Component, OnInit } from '@angular/core';
import { Card } from '../models/card';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'mean-cardList',
  templateUrl: './cardList.component.html',
  styleUrls: ['./cardList.component.css']
})
export class CardListComponent implements OnInit {

  welcome = {
    header: 'Card Manager',
    message: ""
  }

  cards = new Array<Card>();

  constructor(private backendService: BackendService) { }

  ngOnInit(): void {
    this.backendService.getCards()
      .subscribe(resp => {
        this.cards = resp;
      });

  }
}
