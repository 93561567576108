<h2>{{card.id}}: <span *ngIf="!editType" (dblclick)="toggle('type')">{{card.type}}</span><input *ngIf="editType"
        type="string" [(ngModel)]="card.type" (keyup.enter)="save('type')" /></h2>
<div class="container">
    <!-- <div class="card" style="background-image: url('https://cdn.tectonicalliance.com/cards/{{card.id}}.png'); background-size: contain;"> -->
    <div class="card" style="background-image: url('assets/cards/{{card.id}}.png'); background-size: contain;">
        <section *ngIf="card.cost" class="cost">
            <input *ngIf="editCost" type="number" [(ngModel)]="card.cost" (keyup.enter)="save('cost')" />
            <p *ngIf="!editCost" (dblclick)="toggle('cost')">{{card.cost}}</p>
        </section>
        <section *ngIf="card.type == 'unit'" class="health">
            <input *ngIf="editHealth" type="text" [(ngModel)]="card.health" (keyup.enter)="save('health')" />
            <p *ngIf="!editHealth" (dblclick)="toggle('health')">{{card.health}}</p>
        </section>
        <section *ngIf="card.type == 'spell'" class="health">
            <input *ngIf="editPriority" type="text" [(ngModel)]="card.priority" (keyup.enter)="save('priority')" />
            <p *ngIf="!editPriority" (dblclick)="toggle('priority')">{{card.priority}}</p>
        </section>
        <section *ngIf="card.type == 'environment'" class="health">
            <input *ngIf="editDuration" type="text" [(ngModel)]="card.duration" (keyup.enter)="save('duration')" />
            <p *ngIf="!editDuration" (dblclick)="toggle('duration')">{{card.duration}}</p>
        </section>
        <section *ngIf="card.type == 'unit'" class="range">
            <input *ngIf="editRange" type="number" [(ngModel)]="card.range" (keyup.enter)="save('range')" />
            <p *ngIf="!editRange" (dblclick)="toggle('range')">{{card.range}}</p>
        </section>
        <section *ngIf="card.type == 'spell'" class="range">
            <input *ngIf="editRange" type="number" [(ngModel)]="card.range" (keyup.enter)="save('range')" />
            <p *ngIf="!editRange" (dblclick)="toggle('range')">{{card.range}}</p>
        </section>
        <section *ngIf="card.type == 'unit'" class="movement">
            <input *ngIf="editMovement" type="text" [(ngModel)]="card.movement" (keyup.enter)="save('movement')" />
            <p *ngIf="!editMovement" (dblclick)="toggle('movement')">{{card.movement}}</p>
        </section>
        <section class="name">
            <input *ngIf="editName" type="text" [(ngModel)]="card.name" (keyup.enter)="save('name')" />
            <p *ngIf="!editName" (dblclick)="toggle('name')">{{card.name}}</p>
        </section>
        <section class="ability">
            <input *ngIf="editAbility" type="text" [(ngModel)]="card.ability" (keyup.enter)="save('ability')" />
            <p *ngIf="!editAbility" (dblclick)="toggle('ability')">{{card.ability}}</p>
        </section>
    </div>
</div>