import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Card } from '../models/card';

@Injectable({
  providedIn: 'root'
})

export class BackendService {
  constructor(private http: HttpClient) { }

  public getCards(): Observable<Card[]> {
    return this.http.get<Card[]>(`${environment.apiUrl}/api/cards`);
  }

  public getCardsByType(type: string): Observable<Card[]> {
    return this.http.get<Card[]>(`${environment.apiUrl}/api/cards?type=${type}`);
  }

  public updateCard(oldCard, newCard): Observable<Card> {
    return this.http.put<Card>(`${environment.apiUrl}/api/cards`, { oldCard: oldCard, newCard: newCard });
  }
}
